<template>
  <v-navigation-drawer
    app
    dark
    temporary
  >
  <h1>Drawer</h1>
    <v-list>
      <v-list-item
        @click="$router.push('/stories')"
      >
        <v-list-item-title>Stories</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'CoreDrawer',
  }
</script>
